import React, { Component, useState, useEffect } from "react"
import "./intro.scss"
import Slider from "react-slick"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const Intro = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    pauseOnHover: true,
    autoplaySpeed: 2000,
    arrows: true,
    className: "carousel",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const intl = useIntl()

  let [showVideo, setShowVideo] = useState(false);

  useEffect( () =>{
      setShowVideo(document.body.clientWidth > 769);

  },[]);

  return (
    <Slider {...settings}>
      <div className="home-intro home-intro_img">
        <div className="container">
          <div className="title-overlay">
            <h2>{intl.formatMessage({ id: "intro.woodProtection" })}</h2>
          </div>
        </div>
      </div>
      <div className="home-intro home-intro_3">
        <Link  to={"/chalk-it"}>
        <div className="container">
          <div className="title-overlay">
            <h2>{intl.formatMessage({ id: "intro.сhalkPaints" })}</h2>
          </div>
        </div>
        </Link>
      </div>
      <div className="home-intro home-intro_2">

        <div className="container">
          <div className="title-overlay">
            <h2>{intl.formatMessage({ id: "intro.luxdecorNorjd" })}</h2>
          </div>
          <div className="title-overlay">
            <h3>{intl.formatMessage({ id: "intro.woodImpregnant" })}</h3>
          </div>
        </div>
      </div>
    </Slider>
  )
}

export default Intro
