import React from "react"
import "./tile.scss";
import { useIntl } from "gatsby-plugin-intl"


const Tile = () => {
  const intl = useIntl()
  return (
      <div id="tile">
        <div className="tile-row">
          <div className="tile-img"></div>
          <div className="tile-content">
            <h2>{intl.formatMessage({ id: 'tile.title1' })}</h2>
            <div className="line"></div>
            <p>{intl.formatMessage({ id: 'tile.desc1' })}</p>
          </div>
        </div>
        <div className="tile-row">
          <div className="tile-img"></div>
          <div className="tile-content">
            <h2>{intl.formatMessage({ id: 'tile.title2' })}</h2>
            <div className="line"></div>
            <p>{intl.formatMessage({ id: 'tile.desc2' })}</p>
          </div>
        </div>
        <div className="tile-row">
          {/*<div className="tile-img"></div>*/}
          <div className="img-container">
              <img src="https://luxdecor.ua/img/luxdecor-01.jpg" alt=""/>
          </div>
          <div className="tile-content">
            <h2>{intl.formatMessage({ id: 'tile.title3' })}</h2>
            <div className="line"></div>
            <p>{intl.formatMessage({ id: 'tile.desc3' })}</p>
          </div>
        </div>
      </div>
    )
};

export default Tile
