import React from "react"
import "./carousel.scss"
import Slider from "react-slick"
import { useIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby"


const Carousel = () => {
  const intl = useIntl()

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    pauseOnHover: true,
    autoplaySpeed: 2000,
    arrows: true,
    className: "carousel",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="home-carousel container">
      <div className="carousel-title">
        <h4>{intl.formatMessage({ id: "carousel.text" })}</h4>
      </div>
      <div className="carousel-body">
        <Slider {...settings}>
          <Link className={"c-slide"} to={"product/luxdecor-stain-varnish"}>
            <img
              src="https://luxdecor.ua/img/luxdecor-lakierobejca.jpg"/>
          </Link>
          <Link className={"c-slide"} to={"product/luxdecor-acrylic-enamel"}>
            <img
              src="https://luxdecor.ua/img/luxdecor-emalia-akrylowa.jpg"/>
          </Link>
          <Link className={"c-slide"} to={"product/luxdecor-impregnating-agent"}>
            <img
              src="https://luxdecor.ua/img/impregnat-01.png"/>
          </Link>
          <Link className={"c-slide"} to={"product/luxdecor-njord-separating-primer"}>
            <img
              src="https://luxdecor.ua/img/luxdecor-grunt-odcinajacy-njord.jpg"/>
          </Link>
        </Slider>
      </div>
    </div>
  )
}

export default Carousel
