import React, {useEffect} from "react"
import Layout from "../components/shared/layout/layout"
import SEO from "../components/shared/ui-elements/seo"
import Intro from "../components/home/intro/intro"
import Carousel from "../components/home/carousel/carousel"
import Tile from "../components/home/tile/tile"
import {useIntl} from "gatsby-plugin-intl";

const IndexPage = () => {
    const intl = useIntl()
    // useEffect( () =>{
    //     if(localStorage.getItem('user-move') !=='uk'){
    //         localStorage.setItem('user-move','uk')
    //         localStorage.setItem('gatsby-intl-language','uk')
    //         window.location.pathname = `/uk`
    //     }
    // },[]);
    return (
        <Layout>
            <SEO title={intl.formatMessage({id: `seo.home.title`})}
                 description={intl.formatMessage({id: `seo.home.desc`})}/>
            <Intro/>
            <Carousel/>
            <Tile/>
        </Layout>
    )
}

export default IndexPage
